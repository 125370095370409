import { httpRequest } from '@sales-i/utils';

const state = () => ({
  loading: false,
  loaded: true,
  errors: [],
});

const mutations = {
  'addFeedback': (state) => {
    state.loaded = true;
    state.loading = false;
    state.errors = [];
  },
  'addFeedbackLoading': (state) => {
    state.loaded = false;
    state.loading = true;
    state.errors = [];
  },
  'addFeedbackError': (state, e) => {
    state.loaded = true;
    state.loading = false;
    state.errors = [e];
  },
};

const getters = {};

const actions = {
  'addFeedback': async ({ commit }, feedback) => {
    commit('addFeedbackLoading');
    try {
      await httpRequest('post', 'conversation/feedback', feedback, {
        version: '2',
        area: 'enquiries',
      });

      commit('addFeedback');
    } catch (e) {
      commit('addFeedbackError', e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};