<template>
  <component
    :is="funComponent().component"
  />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import FunWhat from './FunWhat.vue';
import FunBelieve from './FunBelieve.vue';

const store = useStore();

const getFunActionVerb = computed(() => store.getters['conversation/getFunActionVerb']);

const getFunQuestionType = () => {
  return `fun${getFunActionVerb.value}`;
};

const funComponent = () => {
  const components = {
    'funbelieve': {
      component: FunBelieve
    },
    'funwhat': {
      component: FunWhat,
    },
  };

  return components[getFunQuestionType()];
};

</script>