// System Actions
export const SET_ITEMS = 'SET_ITEMS';
export const REMOVE_BY_INDEX = 'REMOVE_BY_INDEX';
export const SET_ENTITY = 'SET_ENTITY';
export const CLEAR_ENTITY = 'CLEAR_ENTITY';
export const SET_ROUTE = 'SET_ROUTE';
export const PUSH_ROUTE = 'PUSH_ROUTE';
export const CLEAR_ROUTE = 'CLEAR_ROUTE';
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE';
export const SET_IFRAME = 'SET_IFRAME';
export const GET_TENANT = 'GET_TENANT';
export const GET_TENANT_LOADING = 'GET_TENANT_LOADING';
export const GET_TENANT_ERROR = 'GET_TENANT_ERROR';

// Conversation actions
export const AI_QUERY_CONVERSE = 'AI_QUERY_CONVERSE';
export const AI_QUERY_CONVERSE_LOADING = 'AI_QUERY_CONVERSE_LOADING';
export const AI_QUERY_CONVERSE_ERROR = 'AI_QUERY_CONVERSE_ERROR';

export const AI_QUERY_METHOD = 'AI_QUERY_METHOD';
export const AI_QUERY_METHOD_LOADING = 'AI_QUERY_METHOD_LOADING';
export const AI_QUERY_METHOD_ERROR = 'AI_QUERY_METHOD_ERROR';

export const AI_CLEAR_QUERY_RESPONSE = 'AI_CLEAR_QUERY_RESPONSE';