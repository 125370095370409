<template>
  <div>
    <h3>{{ getFunVerbiage() }}</h3>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';

const store = useStore();

const getFunQuestion = computed(() => store.getters['conversation/getFunQuestion']);

const getFunVerbiage = () => {
  let x = 0;
  let verbiage = '';
  for (x; x < getFunQuestion.value.entities.length; x++) {
    const _ref = getFunQuestion.value.entities[x];

    if (_ref.text.toLowerCase().includes('santa') || _ref.text.toLowerCase().includes('father')) {
      verbiage = t('Of course I believe in Santa, where do you think all the presents come from? Definitely not the Rainforest Company');
    }
    if (_ref.text.toLowerCase().includes('easter')) {
      verbiage = t('Of course I believe in the Easter Bunny, where do you think all the eggs come from? Definitely not some Chocolate factory');
    }
  }

  return verbiage;
};

</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  margin: var(--spacing-2) auto;
  width: 80%;
}
</style>