<template>
  <CustomModal
    :title="t('We value your feedback')"
    :show-modal="props.showModal"
    @close-modal="closeModal"
  >
    <div class="feedback-form">
      <p
        v-if="errors.length > 0"
        class="error"
      >
        {{ errors[0].errors[0].message }}
      </p>
      <p>{{ getSentimentVerbiage() }}</p>
      <Field
        v-model="feedbackInformation"
        type="textarea"
        @input="updateFeedbackInformation($event)"
      />

      <CustomButton
        :label="t('Submit Feedback')"
        @on-click="recordFeedback"
      />
    </div>
  </CustomModal>
</template>

<script setup>
import { ref, computed } from 'vue';
import { t } from '@sales-i/utils';
import { useStore } from 'vuex';

import { CustomModal, CustomButton } from '@sales-i/dsv3';
import Field from '@/components/Form/Field.vue';

const emit = defineEmits(['closeModal']);

const store = useStore();

const errors = computed(() => store.state.voicedQueryFeedback.errors || []);

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
  queryId: {
    type: Number,
    default: 0,
  },
  positiveFeedback: {
    type: Boolean,
    default: false,
  },
  feedbackIntentCategory: {
    type: String,
    default: '',
  },
});

const feedbackInformation = ref('');

const getSentimentVerbiage = () => {
  let verbiage = '';
  if (props.positiveFeedback) {
    verbiage = 'We are glad you found what you were looking for';
  } else {
    verbiage = 'We are sorry that you did not find what you were looking for';
  }

  verbiage += '. Your feedback helps us improve.';

  return t(verbiage);
};

const updateFeedbackInformation = ($event) => {
  feedbackInformation.value = $event;
};

const closeModal = () => {
  emit('closeModal');
};

const recordFeedback = async () => {
  await store.dispatch('voicedQueryFeedback/addFeedback', {
    intent_name: props.feedbackIntentCategory,
    feedback_response: feedbackInformation.value,
    feedback_positive: props.positiveFeedback,
    query_id: props.queryId,
  });

  // Check we have no errors, if not then grand
  if (errors.value.length === 0) {
    emit('closeModal');
  }
};
</script>

<style lang="scss" scoped>

.feedback-form {
  padding: var(--spacing-2);
}

.error {
  background-color: var(--colour-brand-mandy);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--border-radius-1);
  margin: 0 0 var(--spacing-1) 0;
}

</style>