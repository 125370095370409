import {
  SET_ENTITY,
  CLEAR_ENTITY,
  SET_ROUTE,
  PUSH_ROUTE,
  CLEAR_ROUTE,
  SET_MAINTENANCE_MODE,
  SET_IFRAME,
  GET_TENANT,
  GET_TENANT_ERROR,
  GET_TENANT_LOADING
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

// initial state
const state = () => ({
  entity: undefined,
  route: {},
  maintenance_mode: false,
  iframe: false,
  tenant: {
    data: {},
    loading: false,
    errors: [],
  },
});

// getters
const getters = {
  tenantId: (state, getters, rootState) => rootState.userDetails.data.hubs 
    && rootState.userDetails.data.hubs[0] 
    && rootState.userDetails.data.hubs[0].id,
  isDemo: (state, getters,) => {
    // state & getters needed to get to rootState
    const demoTenants = ['00100232-08cc-4248-b2d2-2fc1583e60a5'];
    
    // Use for testing
    // const demoTenants = ['00101581-3dd4-40b8-a2e3-175624586f85'];
    
    let tenant_id = getters.tenantId;
    return demoTenants.includes(tenant_id);
  },
  singularEntity: (state, ) => {
    if (!state.entity) return state.entity;
    switch (state.entity) {
    case 'opportunities':
      return 'opportunity';    
    default:
      return state.entity.slice(0,-1);
    }
  },
};

// mutations
const mutations = {
  [SET_ENTITY]: (state, entity) => {
    state.entity = entity;
  },
  [CLEAR_ENTITY]: (state) => {
    state.entity = undefined;
  },
  [SET_ROUTE]: (state, route) => {
    state.route = route;
  },
  [CLEAR_ROUTE]: (state) => {
    state.route = {};
  },
  [SET_MAINTENANCE_MODE]: (state, enableMaintenance) => {
    state.maintenance_mode = enableMaintenance;
  },
  [SET_IFRAME]: (state, enableIframe) => {
    state.iframe = enableIframe;
  },
  [GET_TENANT]: (state, data) => {
    state.tenant.data = data;
    state.tenant.loading = false;
    state.tenant.errors = [];
  },
  [GET_TENANT_LOADING]: (state) => {
    state.loading = true;
  },
  [GET_TENANT_ERROR]: (state, errors) => {
    state.tenant.errors = errors;
  },
};

// actions
const actions = {
  [SET_ENTITY] ({ commit, }, entity) {
    commit(SET_ENTITY, entity);
  },
  [CLEAR_ENTITY] ({ commit, }) {
    commit(CLEAR_ENTITY);
  },
  [SET_ROUTE] ({ commit, }, route) {
    commit(SET_ROUTE, route);
  },
  [PUSH_ROUTE] ({ commit, state }, { defaultUrl, success }) {
    if (success && state.route.success) {
      navigateToUrl(state.route.success);
    } else if (!success && state.route.cancel) {
      navigateToUrl(state.route.cancel);
    } else {
      navigateToUrl(defaultUrl);
    }
    commit(CLEAR_ROUTE);
  },
  [CLEAR_ROUTE] ({ commit, }) {
    commit(CLEAR_ROUTE);
  },
  [SET_MAINTENANCE_MODE] ({ commit, }, enableMaintenance) {
    commit(SET_MAINTENANCE_MODE, enableMaintenance);
  },
  [SET_IFRAME] ({ commit, }, enableIframe) {
    commit(SET_IFRAME, enableIframe);
  },
  [GET_TENANT]: async ({ commit, }) => {
    try {
      commit(GET_TENANT_LOADING);
      const data = await httpRequest('get', 'tenants/current', {}, {
        version: '2',
        area: 'iam'
      });
      commit(GET_TENANT, data);
    } catch (error) {
      console.error(error);
      commit(GET_TENANT_ERROR, error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
