<template>
  <div
    class="wrapper"
  >
    <CustomButton
      small
      class="back-to-search"
      :label="t('Back to search')"
      @on-click="closeResults"
    />
    <table>
      <thead>
        <tr>
          <th
            v-for="item in props.headers"
            :key="item.field"
            class="heading"
          >
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in props.data"
          :key="item.name"
        >
          <td
            v-for="header in props.headers"
            :key="`${header.field}-${item.name}`"
          >
            <p>{{ sanitisedValue(item, header.field) }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { CustomButton } from '@sales-i/dsv3';
import { t, currency } from '@sales-i/utils';

const emit = defineEmits(['closeResults']);

const props = defineProps({
  headers: {
    type: Array,
    default: () => [],
  },
  data: {
    type: Array,
    default: () => [],
  },
});

const sanitisedValue = (field, header) => {
  if (['profit', 'gross_profit', 'average_price', 'cost', 'sales_value', 'value'].includes(header)) {
    return currency.price(field[header]);
  }
  return field[header];
};

const closeResults = () => {
  emit('closeResults');
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.wrapper {
  overflow-y: scroll;
}

.back-to-search {
  margin: var(--spacing-2);
}

table {
  thead {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;
  }

  tbody {
    margin-top: 73px;
  }

  td,
  th {
    vertical-align: middle;
    font-size: var(--font-size-body);
    color: var(--colour-utility-black);
    text-align: center;
    border: 0;
    position: relative;
  }

  td {
    font-size: var(--font-size-small);
    padding: var(--spacing-1) 0;
  }

  thead {
    th {
      min-width: 120px;
      padding: var(--spacing-2) var(--spacing-2) var(--spacing-4);
      background: var(--colour-panel-action);
      color: var(--colour-utility-action);
      border-bottom: 1px solid var(--colour-panel-g-16);
      position: relative;
      top: 0;
      z-index: 1;
      text-decoration: underline;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        min-width: 128px;
      }

      .heading {
        overflow: visible;
        white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 24px;
      }
    }
  }

  tbody {
    tr {
      &:not(.tr-hidden) {
        th,
        td {
          padding-top: var(--spacing-3);
          .drill {
            align-items: flex-end;
            padding-top: var(--spacing-3);
          }
        }
        & ~ tr:not(.tr-hidden) {
          th,
          td {
            padding-top: var(--spacing-1);
            .drill {
              align-items: center;
              padding-top: var(--spacing-2);
            }
          }
        }
      }
    }
  }
  tr {
    td {
      &.row-title {
        // text-align: left;
        padding-left: var(--spacing-2);
        cursor: help;
        background: var(--colour-panel-g-2);

        p:not(.bubble-subtitle) {
          font-weight: var(--font-weight-semibold);
        }

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 195px;
      }
    }
  }
}
</style>