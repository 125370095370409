export const baseUrl = '/intelligence';
export const baseCrmUrl = '/crm';
export const reportScope = 'report';

// higher scopes
export const interactionsScope = 'interactions';
export const performanceScope = 'performance';
export const productsScope = 'products';
export const customerScope = 'customer';
export const enquiryScope = 'enquiry';
export const opportunitiesScope = 'opportunities';
export const communitiesScope = 'communities';
export const associationsScope = 'associations';
export const insightsScope = 'insights';
export const productSearchScope = 'product-search';
export const bubbleScope = 'bubbles';

// snapshot reports
export const snapshotReportScope = 'snapshot';
export const branchScope = 'branch';

// Generic 'People' page for demo
export const peopleScope = 'people';
