<template>
  <div class="field">
    <template v-if="['date', 'text', 'password', 'number', 'email'].includes(type)">
      <CustomInput
        :id="id"
        :for="id"
        :label="label"
        :placeholder="placeholder"
        :type="type"
        :min="min"
        :max="max"
        :min-length="minLength"
        :max-length="maxLength"
        :required="required"
        :disabled="disabled"
        :value="controlledValue"
        :error="error ? true : null"
        :error-message="errorMessage || minMaxErrorMessage"
        :icon-name="icon.name"
        :icon-color="icon.color"
        :icon-align="icon.align"
        :icon-outside="icon.outside"
        :icon-width="icon.size"
        :icon-height="icon.size"
        :icon-function="icon.func"
        :icon-disabled="!!icon.disabled"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </template>
    <template v-if="['textarea'].includes(type)">
      <CustomTextarea
        :id="id"
        :for="id"
        :label="label"
        :placeholder="placeholder"
        :type="type"
        :rows="rows"
        :required="required"
        :max-length="maxLength"
        :disabled="disabled"
        :value="controlledValue"
        :error="!!error ? true : null"
        :error-message="errorMessage"
        :icon-name="icon.name"
        :icon-color="icon.color"
        :icon-align="icon.align"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </template>
    <template v-if="['select'].includes(type)">
      <CustomSelect
        :id="id"
        :placeholder="$t('Please select')"
        :for="id"
        :label="label"
        :items="computedOptions"
        :value="controlledValue"
        :required="required"
        :disabled="disabled"
        :error="error ? true : null"
        :error-message="errorMessage"
        :icon-name="icon.name"
        :icon-color="icon.color"
        :icon-outside="icon.outside"
        :key-field="keyField"
        :label-field="labelField"
        :emit-key="emitKey"
        @input="handleInput"
      />
    </template>
    <template v-if="['filter'].includes(type)">
      <MenuFilter
        :id="id"
        tabindex="0"
        :items="options"
        :label="label"
        :selected-value="controlledValue"
        :menu-position="menuPosition"
        @on-change="handleInput"
      />
    </template>
    <template v-if="['switch'].includes(type)">
      <ToggleSwitch
        :id="id"
        :label="label"
        tabindex="0"
        :selected-value="controlledValue"
        @on-change="handleInput"
      />
    </template>
    <template v-if="['checkbox'].includes(type)">
      <CustomCheckbox
        :id="id"
        :label="label"
        :value="value"
        :disabled="disabled"
        @input="handleInput"
      />
    </template>
    <template v-if="['radio'].includes(type)">
      <CustomRadio
        :id="id"
        :value="id"
        :checked="!!value"
        :name="name"
        :label="label"
        @on-change="handleInput"
      />
    </template>
    <template v-if="['multiselect'].includes(type)">
      <MultiSelect
        :id="id"
        :placeholder="placeholder || $t('Please select')"
        :label="label"
        :items="options"
        :value="value"
        :required="required"
        :disabled="disabled"
        :error="error ? true : null"
        :error-message="errorMessage"
        :show-selected="showSelected"
        :opened="opened"
        :options-to-show="optionsToShow"
        :no-options-message="noOptionsMessage"
        @input="handleInput"
      />
    </template>
  </div>
</template>

<script>
import { CustomCheckbox, CustomInput, CustomSelect, MenuFilter, MultiSelect, CustomRadio, CustomTextarea, ToggleSwitch } from '@sales-i/dsv3';
import { dates } from '@sales-i/utils';

const formatDateAsISO = dates.formatDateAsISO;

export default {
  name: 'Field',
  components: {
    CustomInput,
    CustomSelect,
    MenuFilter,
    ToggleSwitch,
    CustomCheckbox,
    CustomTextarea,
    MultiSelect,
    CustomRadio,
  },
  props: {
    id: {
      type: [String, Number],
      default: 'id',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    minLength: {
      type: Number,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 4,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    externalError: {
      type: Boolean,
      default: false,
    },
    regex: {
      type: [RegExp, String, Boolean],
      default: false,
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
    showSelected: {
      type: Boolean,
      default: true,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    optionsToShow: {
      type: Number,
      default: 6,
    },
    noOptionsMessage: {
      type: String,
      default: 'No options to select',
    },
    selectAll: {
      type: Boolean,
      default: true,
    },
    searchFunc: {
      type: Function,
      default: (t, l) => this.getSearchResults(t, l),
    },
    closeAfterSelecting: {
      type: Boolean,
      default: true,
    },
    highlight: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: 32,
    },
    btnSize: {
      type: Number,
      default: 24,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    twoway: {
      type: Boolean,
      default: false,
    },
    tagProps: {
      type: Object,
      default: () => ({}),
    },
    keyField: {
      type: String,
      default: 'value',
    },
    labelField: {
      type: String,
      default: 'text',
    },
    emitKey: {
      type: Boolean,
      default: true,
    },
    menuPosition: {
      type: String,
      default: 'right',
      validator: function (value) {
        // The value must match one of these strings
        return ['left', 'right'].indexOf(value) !== -1;
      },
    },
  },
  emits: ['input', 'valid', 'focus', 'blur', 'keydown', 'keypress', 'keyup'],
  data() {
    return {
      focused: false,
      internalError: false,
      editedValue: this.cleanValue(this.value),
      emitAllErrorOnInputFor: ['multisearch', 'customersearch'],
    };
  },
  computed: {
    controlledValue: {
      get() {
        let val = this.twoway ? this.value : this.editedValue; // support for two-way binding
        return val && this.type === 'date' ? val.substr(0, 10) : val; // date input workaround
      },
      set(v) {
        this.editedValue = v;
      },
    },
    computedOptions() {
      if (!this.required && this.type === 'select') {
        return [
          {
            text: this.$t('Please select'),
            value: '',
          },
          ...this.options,
        ];
      }
      return this.options;
    },
    error() {
      return this.internalError || this.externalError || !this.isMinMaxValid;
    },
    errorMin() {
      return this.min === +this.min && this.cleanValue(this.controlledValue) < this.min;
    },
    errorMax() {
      return this.max === +this.max && this.cleanValue(this.controlledValue) > this.max;
    },
    isMinMaxValid() {
      return !['number'].includes(this.type) || (!this.errorMin && !this.errorMax);
    },
    minMaxErrorMessage() {
      if (this.isMinMaxValid) return '';
      if (this.errorMin && this.errorMax)
        return this.$t(`Value must be in range ${this.min} .. ${this.max}`, 'value_must_be_in_range_min_max', {
          interpolations: {
            min: this.min,
            max: this.max,
          },
        });
      if (this.errorMin)
        return this.$t(`Min. value is ${this.min}`, 'min_value_is_value', {
          interpolations: {
            value: this.min,
          },
        });
      return this.$t(`Max. value is ${this.max}`, 'max_value_is_value', {
        interpolations: {
          value: this.max,
        },
      });
    },
  },
  watch: {
    value: function (value) {
      // A fix for two way binding, and setting the value outside of
      // editing the field directly
      if (this.twoway) {
        this.editedValue = value;
      }
    },
  },
  methods: {
    cleanValue(v) {
      switch (this.type) {
      case 'number':
        return +v;
      case 'switch':
      case 'radio':
      case 'checkbox':
        return !!v || false;
      case 'date':
        return formatDateAsISO(v) || '';
      case 'select':
        return v;
      default:
        return v || '';
      }
    },
    handleInput(value) {
      this.controlledValue = value;
      this.$emit('input', this.cleanValue(value));
      let error = !this.regexTest(value) || !this.isMinMaxValid;
      if (error === false || this.emitAllErrorOnInputFor.includes(this.type)) {
        this.internalError = error;
      }
      this.$emit('valid', {
        id: this.id,
        value: this.cleanValue(value),
        isValid: error === false,
      });
    },
    handleFocus() {
      this.$emit('focus');
      this.focused = true;
    },
    handleBlur() {
      this.$emit('blur');
      this.internalError = !this.regexTest(this.editedValue);
      this.$emit('valid', {
        id: this.id,
        value: this.cleanValue(this.editedValue),
        isValid: this.error === false,
      });
    },
    handleKeydown(event) {
      this.$emit('keydown', event);
    },
    handleKeypress(event) {
      this.$emit('keypress', event);
    },
    handleKeyup(event) {
      this.$emit('keyup', event);
    },
    regexTest(value) {
      if (this.regex && value) {
        return new RegExp(this.regex).test(value);
      }
      if (this.required && !value) {
        return false;
      }
      if (this.required && !this.regex) {
        return !!value;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.field.title {
  font-size: var(--font-size-5) !important;
  font-family: EuclidCircularA !important;
  letter-spacing: 0 !important;
  line-height: var(--spacing-3);
  font-weight: 400;
}
</style>
