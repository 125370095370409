// import Vue from 'vue';
import { createStore } from 'vuex';
import { httpRequestRaw } from '@sales-i/utils';
import system from './modules/system';
import conversation from './modules/conversation';
import voicedQueryFeedback from './modules/voicedQueryFeedback';
import modalQueue from './modules/modalQueue';

// const debug = process.env.NODE_ENV !== 'production';

// Vue.config.devtools = debug;

// Plugins Examples
const subscribe = store => {
  // eslint-disable-next-line no-unused-vars
  store.subscribe((mutation, state) => {});
};

const store = createStore({
  modules: {
    system,
    conversation,
    voicedQueryFeedback,
    modalQueue,
  },
  strict: true,//debug,
  plugins: [subscribe]
});

store.$http = httpRequestRaw;

export default store;
