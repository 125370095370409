import {
  SET_ITEMS,
  REMOVE_BY_INDEX,
} from '@/shared/store/actionType';
import { t } from '@sales-i/utils';

// initial state
const state = () => ({
  data: [],
  no_roles_pbac_error: false,
});

// getters
const getters = {
};

// mutations
const mutations = {
  [SET_ITEMS]: (state, data) => {
    if (Array.isArray(data) && data[0].message === 'unable to set pbac roles for user') {
      if (!state.no_roles_pbac_error) {
        data[0].title = t('No permissions');
        data[0].message = t('User has no permissions and cannot access the application. Please contact your administrator for access.');
        data[0].closeFunction = () => {
          window.location = process.env.VUE_APP_AUTH_REDIRECT_URI;
        };
        state.data = state.data.concat(data);   
      }
      state.no_roles_pbac_error = true;
    } else {
      state.data = state.data.concat(data);
    }
  },
  [REMOVE_BY_INDEX]: (state, index) => {
    state.data.splice(index, 1);
  },
};

// actions
const actions = {
  [SET_ITEMS] ({ commit, }, payload) {
    commit(SET_ITEMS, payload);
  },
  [REMOVE_BY_INDEX] ({ commit, }, index) {
    commit(REMOVE_BY_INDEX, index);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
