<template>
  <div
    v-if="props.queryResponse.length"
  >
    <Table
      :headers="[
        {
          text: t('Name'),
          field: 'name',
        },
        {
          text: t('Value'),
          field: 'value',
        },
        {
          text: t('Profit'),
          field: 'profit',
        },
        {
          text: t('Gross Profit'),
          field: 'gross_profit',
        },
        {
          text: t('Avg. Price'),
          field: 'average_price',
        },
        {
          text: t('Cost'),
          field: 'cost',
        },
        {
          text: t('Sales Value'),
          field: 'sales_value',
        },
        {
          text: t('Quantity'),
          field: 'quantity',
        },
      ]"
      :data="props.queryResponse"
      @close-results="closeResults"
    />
  </div>
</template>

<script setup>
import { t } from '@sales-i/utils';

import Table from '@/components/Table/Table.vue';

const props = defineProps({
  queryResponse: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['closeResults']);

const closeResults = () => {
  emit('closeResults');
};

</script>