import {
  AI_QUERY_CONVERSE,
  AI_QUERY_CONVERSE_LOADING,
  AI_QUERY_CONVERSE_ERROR,
  
  AI_QUERY_METHOD,
  AI_QUERY_METHOD_LOADING,
  AI_QUERY_METHOD_ERROR,

  AI_CLEAR_QUERY_RESPONSE,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

const state = () => ({
  loading: false,
  loaded: true,
  response: {},
  queryResponse: [],
  errors: [],
});

const getters = {
  getAllOrderedIntents: (state) => {
    if ('intents' in state.response && state.response.intents !== null) {
      const { intents } = state.response;
      return [...intents].sort((a, b) => {
        if (a.confidence_score > b.confidence_score) {
          return -1;
        } else if (a.confidence_score < b.confidence_score) {
          return 1;
        }
        return 0;
      }).slice(0, 3);
    }
    return [];
  },
  getOrderedIntents: (state) => {
    if ('intents' in state.response && state.response.intents !== null) {
      const { intents } = state.response;
      const fIntents = [...intents].filter(o => o.intent_category !== 'FunQuestions');
      return [...fIntents].sort((a, b) => {
        if (a.confidence_score > b.confidence_score) {
          return -1;
        } else if (a.confidence_score < b.confidence_score) {
          return 1;
        }
        return 0;
      });
    }
    return [];
  },
  hasFunQuestion: (state, getters) => {
    const intents = getters.getAllOrderedIntents;
    if (intents.length > 0) {
      return intents[0].intent_category === 'FunQuestions';
    }
  },
  // Here we are making an assumption, that you've checked the fun question is
  // the top intent as described above, if you're calling this without
  // checking the result of the above function, you're being a little
  // foolish tbqh
  getFunQuestion: (state, getters) => {
    const intents = getters.getAllOrderedIntents;
    if (intents.length > 0) {
      return {
        intent: intents[0],
        entities: state.response.entities,
      };
    }
    return {};
  },
  getFunActionVerb: (state) => {
    if (state.response.entities.length > 0) {
      let verb = state.response.entities.find(o => o.category === 'FunActionVerb');
      if (verb !== undefined) {
        return verb.text.toLowerCase();
      }
      // Now check to see if there is an adverb
      verb = state.response.entities.find(o => o.category === 'FunAdverb');
      if (verb !== undefined) {
        return verb.text.toLowerCase();
      }
    }
    return 'believe';
  },
};

const mutations = {
  [AI_QUERY_CONVERSE]: (state, response) => {
    state.response = response;
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  [AI_QUERY_CONVERSE_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [AI_QUERY_CONVERSE_ERROR]: (state, error) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [error];
  },

  [AI_QUERY_METHOD]: (state, response) => {
    state.queryResponse = response;
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  [AI_QUERY_METHOD_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [AI_QUERY_METHOD_ERROR]: (state, error) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [error];
  },

  [AI_CLEAR_QUERY_RESPONSE]: (state) => {
    state.queryResponse = [];
  }
};

const actions = {
  [AI_QUERY_CONVERSE]: async ({ commit }, prompt) => {
    commit(AI_QUERY_CONVERSE_LOADING);
    try {
      const data = await httpRequest('post', 'conversation', {
        prompt,
      }, {
        version: '2',
        area: 'enquiries'
      });

      commit(AI_QUERY_CONVERSE, data);
    } catch (e) {
      console.error(e);
      commit(AI_QUERY_CONVERSE_ERROR, e);
    }
  },

  [AI_QUERY_METHOD]: async ({ commit }, [method, body]) => {
    commit(AI_QUERY_METHOD_LOADING);
    try {
      const data = await httpRequest('post', `conversation/${method}`, {
        ...body,
      }, {
        version: '2',
        area: 'enquiries'
      });
      commit(AI_QUERY_METHOD, data);
    } catch (e) {
      console.error(e);
      commit(AI_QUERY_METHOD_ERROR, e);
    }
  },

  [AI_CLEAR_QUERY_RESPONSE]: ({ commit }) => {
    commit(AI_CLEAR_QUERY_RESPONSE);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
